import {
  Timestamp,
  arrayUnion,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { widgetDB, widgetStorage } from "./firebase";
import { WidgetDoc } from "./types";
import { ChatDoc } from "../types";
import { setSessionStorage } from "../hooks/use-session-storage";
import uuidv4 from "../utils/uuidv4";
import { ref, uploadBytes } from "firebase/storage";

//-------------------------------------------------------------------

export const DEFAULT_LOGO =
  "https://firebasestorage.googleapis.com/v0/b/openai-widget-dev.appspot.com/o/widgets%2Fdefault-icons%2Flogo_agencii.svg?alt=media&token=f96284f9-f859-45b0-acc3-e283de0e1e73";

//-------------------------------------------------------------------

export const getWidgetById = async (
  widgetId: string
): Promise<WidgetDoc | null> => {
  const docRef = doc(widgetDB, `widgets/${widgetId}`);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    return null;
  }

  const widget = docSnap.data() as WidgetDoc;
  return widget;
};

// Function to check if a widget is PRO - used for continuous verification
export const verifyWidgetProStatus = async (
  widgetId: string
): Promise<boolean> => {
  const widget = await getWidgetById(widgetId);

  if (!widget) {
    return false;
  }

  const ownerUid = widget.ownerUid;
  if (!ownerUid) {
    return false;
  }

  // Fetch the user document using the ownerUid
  const userDocRef = doc(widgetDB, `users/${ownerUid}`);
  const userDocSnap = await getDoc(userDocRef);

  if (!userDocSnap.exists()) {
    return false;
  }

  const userData = userDocSnap.data();

  // Check if the user plan is "developer"
  if (userData?.stripe?.plan === "developer") {
    return true;
  }

  // Check if the widget itself is marked as PRO
  if (!!widget?.isPRO) {
    return true;
  }

  // Check if the user's plan is set to cancel at the end of the period and is "free"
  if (
    userData?.stripe?.cancelAtPeriodEnd &&
    userData?.stripe?.plan === "free"
  ) {
    return true;
  }

  // If none of the conditions are met, return false
  return false;
};

export const createChatDoc = async (
  widgetId: string,
  ownerUid: string
): Promise<string> => {
  const newDocId = doc(collection(widgetDB, `widgets/${widgetId}/chats`)).id;

  const newChat: ChatDoc = {
    chatId: newDocId,
    createdAt: Timestamp.now(),
    messages: [],
    createdBy: "user",
    widgetId,
    ownerUid,
    integrationOwnerUid: ownerUid,
  };

  const docRef = doc(widgetDB, `widgets/${widgetId}/chats`, newDocId);

  await setDoc(docRef, newChat);

  return newDocId;
};

export const getMessage = async (
  widgetId: string,
  chatId: string,
  message: string
) => {
  try {
    const docRef = doc(widgetDB, `widgets/${widgetId}/chats`, chatId);

    const newId = uuidv4();
    const clientTimeStamp = Timestamp.now();

    await updateDoc(docRef, {
      messages: arrayUnion({
        type: "text",
        role: "user",
        content: message,
        createdAt: clientTimeStamp,
        id: newId,
      }),
      lastUpdated: clientTimeStamp,
      lastMessageTimestamp: {
        id: newId,
        timestamp: serverTimestamp(),
      },
    });

    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      const data = docSnapshot.data();

      // Get the processed serverTimestamp for the last message
      const serverTimestampProcessed = data.lastMessageTimestamp?.timestamp;

      if (serverTimestampProcessed instanceof Timestamp) {
        // Use the server-provided timestamp to update the message's createdAt field
        const updatedMessages = (data.messages || []).map((msg: any) => {
          if (msg.id === newId) {
            return {
              ...msg,
              createdAt: serverTimestampProcessed, // Use the accurate server timestamp
            };
          }
          return msg;
        });

        // Step 3: Update the messages array with the accurate server-side createdAt timestamp
        await updateDoc(docRef, {
          messages: updatedMessages,
        });
      }
    }
  } catch (error: any) {
    if (error?.code === "functions/deadline-exceeded") {
      throw new Error("Sorry, your request timed out.");
    }
    if (JSON.stringify(error).toLocaleLowerCase().includes("timeout")) {
      throw new Error("Timeout. Please try again.");
    }
    if (JSON.stringify(error).toLocaleLowerCase().includes("internal")) {
      throw new Error("Something went wrong. Please try again.");
    }
    throw error;
  }
};

export const clearAllConversation = async (
  widgetId: string,
  chatId: string
) => {
  const docRef = doc(widgetDB, `widgets/${widgetId}/chats`, chatId);

  setSessionStorage(`user-widget-id-${widgetId}`, "");
};

export async function uploadVoiceInput({
  file,
  widgetId,
  chatId,
}: {
  file: Blob;
  widgetId: string;
  chatId: string;
}) {
  let root: string;
  let id: string;

  switch (true) {
    case !!widgetId:
      root = "widgets";
      id = widgetId;
      break;
    default:
      throw new Error("No id provided");
  }

  const storageRef = ref(
    widgetStorage,
    `voice-inputs/${root}/${id}/${chatId}/${uuidv4()}.wav`
  );

  try {
    const snapshot = await uploadBytes(storageRef, file);
    return storageRef.fullPath;
  } catch (error) {
    console.error("Upload failed", error);
    throw error;
  }
}
