import { useCallback, useEffect, useState, useRef } from "react";
import { useDebounce } from "./use-debounce";
import { useDebounceCallback } from "./use-debounce-callback";

interface UseScrollHandlerOptions {
  stickToBottomThreshold?: number;
  scrollIndicatorThreshold?: number;
  conversationId?: string;
  isTyping?: boolean;
  messagesLength?: number;
}

interface ScrollState {
  isStickedToBottom: boolean;
  showScrollToBottomIndicator: boolean;
  containerRef: React.RefObject<HTMLDivElement>;
  scrollToBottom: (smooth?: boolean) => void;
}

export function useScrollManagement({
  stickToBottomThreshold = 100,
  scrollIndicatorThreshold = 400,
  conversationId,
  isTyping,
  messagesLength,
}: UseScrollHandlerOptions = {}): ScrollState {
  const containerRef = useRef<HTMLDivElement>(null);
  const isFirstRender = useRef(true);
  const isUserScrollingUp = useRef(false);
  const lastScrollTop = useRef(0);

  const debouncedMessagesLength = useDebounce(messagesLength || 0, 200);
  const messagesArray = Array(debouncedMessagesLength).fill(1);

  const [isStickedToBottom, setIsStickedToBottom] = useState(true);
  const [showScrollToBottomIndicator, setShowScrollToBottomIndicator] =
    useState(false);

  const scrollToBottom = useCallback(
    (smooth = true) => {
      const container = containerRef.current;
      if (container) {
        container.scrollTo({
          top: container.scrollHeight,
          behavior: smooth ? "smooth" : "auto",
        });
        isFirstRender.current = false;
        resetScrollUpStates();
        setIsStickedToBottom(true);
      }
    },
    [containerRef.current]
  );

  const resetScrollUpStates = useCallback(() => {
    isUserScrollingUp.current = false;
    lastScrollTop.current = 0;
  }, []);

  useEffect(() => {
    if (
      !messagesArray.length ||
      !isStickedToBottom ||
      (isTyping && isUserScrollingUp.current)
    )
      return;
    scrollToBottom(!!!isTyping);
  }, [isStickedToBottom, messagesArray, isTyping]);

  const checkScrollPosition = useCallback(() => {
    const container = containerRef.current;
    if (!container) return;

    const { scrollHeight, scrollTop, clientHeight } = container;
    const atBottom =
      Math.abs(scrollHeight - (scrollTop + clientHeight)) <=
      stickToBottomThreshold;

    setIsStickedToBottom(isFirstRender.current ? true : atBottom);
    setShowScrollToBottomIndicator(
      scrollTop < scrollHeight - clientHeight - scrollIndicatorThreshold
    );
  }, [
    containerRef,
    stickToBottomThreshold,
    scrollIndicatorThreshold,
    isFirstRender.current,
  ]);

  const handleScroll = useDebounceCallback(() => checkScrollPosition(), 30);

  const handleUserScroll = useCallback(() => {
    const container = containerRef.current;
    if (!container) return;
    if (!isTyping) {
      resetScrollUpStates();
      return;
    }
    const { scrollTop } = container;

    if (scrollTop < lastScrollTop.current - 45) {
      isUserScrollingUp.current = true;
    }

    lastScrollTop.current = scrollTop;
  }, [isTyping, resetScrollUpStates]);

  const immediateScrollHandler = useCallback(() => {
    handleScroll();
    handleUserScroll();
  }, [handleScroll, handleUserScroll]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const scrollHandler = () => {
      requestAnimationFrame(immediateScrollHandler);
    };

    container.addEventListener("scroll", scrollHandler, {
      passive: true,
    });

    immediateScrollHandler();

    return () => {
      container.removeEventListener("scroll", scrollHandler);
    };
  }, [conversationId, immediateScrollHandler, messagesLength]);

  useEffect(() => {
    if (conversationId) {
      isFirstRender.current = true;
      setIsStickedToBottom(true);
      resetScrollUpStates();
    }
  }, [conversationId]);

  return {
    isStickedToBottom,
    showScrollToBottomIndicator,
    containerRef,
    scrollToBottom,
  };
}
