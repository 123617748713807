import React from "react";

import { getFirestore } from "firebase/firestore";
import type { FC } from "react";
import {
  AuthProvider,
  FirebaseAppProvider,
  FirestoreProvider,
} from "reactfire";
import { appCheckInit, widgetAuth, widgetFirebaseApp } from "../lib/firebase";

// HOC for FirebaseAppProvider
const withFirebaseApp = <P extends object>(Component: FC<P>): FC<P> => {
  return function WithFirebaseApp(props: P) {
    if (!appCheckInit) {
      return null;
    }
    return (
      <FirebaseAppProvider firebaseApp={widgetFirebaseApp}>
        <AuthProvider sdk={widgetAuth}>
          <Component {...props} />
        </AuthProvider>
      </FirebaseAppProvider>
    );
  };
};

// HOC for Providers
const withProviders = <P extends object>(Component: FC<P>): FC<P> => {
  return function WithProviders(props: P) {
    const firestoreInstance = getFirestore(widgetFirebaseApp);

    return (
      <FirestoreProvider sdk={firestoreInstance}>
        <AuthProvider sdk={widgetAuth}>
          <Component {...props} />
        </AuthProvider>
      </FirestoreProvider>
    );
  };
};

// Combining HOCs
export const withFirebaseProviders = <P extends object>(
  Component: FC<P>
): FC<P> => {
  // First wrap the component with FirebaseAppProvider
  const WithFirebaseApp = withProviders(Component);
  // Then wrap WithFirebaseApp with AuthProvider and FirestoreProvider
  return withFirebaseApp(WithFirebaseApp);
};
