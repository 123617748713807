import React from "react";
import { Box, BoxProps } from "@mui/material";
import type { FabProps } from "@mui/material/Fab";

import Fab from "@mui/material/Fab";
import Iconify from "../iconify";

// ----------------------------------------------------------------------
export type ScrollToButtonProps = FabProps & {
  handleScrollBottom: (smooth?: boolean) => void;
  isVisible: boolean;
  boxSx?: BoxProps["sx"];
};

export function ScrollToButton({
  handleScrollBottom,
  isVisible,
  sx,
  boxSx,
  ...other
}: ScrollToButtonProps) {
  return (
    <Box
      sx={{
        mr: 2,
        position: "absolute",
        bottom: isVisible ? 16 : 0,
        right: "50%",
        left: "45%",
        zIndex: 2,
        width: 40,
        height: 40,
        transition: "all 250ms ease-in",
        opacity: isVisible ? "1" : "0",
        visibility: isVisible ? "visible" : "hidden",
        ...boxSx,
      }}
    >
      <Fab
        aria-label={`Back to bottom`}
        onClick={() => handleScrollBottom()}
        color="secondary"
        sx={{
          width: 40,
          height: 40,
          flex: "0 0 40px",
          position: "fixed",
          color: "text.primary",
          zIndex: (theme) => theme.zIndex.speedDial,
          transition: (theme) => theme.transitions.create(["transform"]),
          ...sx,
        }}
        {...other}
      >
        <Iconify width={20} icon={"solar:alt-arrow-down-line-duotone"} />
      </Fab>
    </Box>
  );
}
