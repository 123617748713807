import React from "react";
import { WidgetLayoutWithSettings } from "./openai-widget/layout";
import WidgetChat from "./openai-widget";

import "./index.css";
import { MotionLazy } from "./openai-widget/components/animate/motion-lazy";

export const App = ({
  widgetId,
  additionalInstructions,
}: {
  widgetId: string;
  additionalInstructions?: string;
}) => {
  // const isSharedPrototype = window.location.href.includes("widget-prototype");
  return (
    <MotionLazy>
      <WidgetLayoutWithSettings
        additionalInstructions={additionalInstructions}
        widgetId={widgetId}
      >
        <WidgetChat />
      </WidgetLayoutWithSettings>
    </MotionLazy>
  );
};
