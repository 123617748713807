import React from "react";

import { useContext } from "react";
// @types
//
import ChatMessageItem from "./ChatMessageItem";
import { ChatContext } from "../context";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { useWidgetSettingsContext } from "../settings";
import Scrollbar from "../components/scrollbar";
import { mockResponse } from "../mockData";
import { DEFAULT_LOGO } from "../lib/firestore";
import ChatConversationStarter from "./ChatConversationStarter";
import { useScrollManagement } from "../hooks/use-scroll-management";
import { ScrollToButton } from "../components/scroll-to-button";
import TypingIndicator from "../components/TypingIndicator";

export default function ChatMessageList() {
  const {
    name,
    assistant,
    assistantAvatar,
    conversationStarters,
    description,
    noToolCalls,
  } = useWidgetSettingsContext();

  const {
    messages,
    status,
    errorMessage,
    eventErrorMessage,
    isCreatingChat,
    isFunctionCalling,
    streamingMessageId,
    chatId,
  } = useContext(ChatContext);

  const filteredconversationStarters = conversationStarters
    ? conversationStarters.filter((starter) => starter.text.trim().length)
    : [];

  const {
    containerRef: messagesEndRef,
    showScrollToBottomIndicator,
    scrollToBottom,
  } = useScrollManagement({
    stickToBottomThreshold: 120,
    conversationId: chatId || undefined,
    isTyping: status === "typing",
    messagesLength: messages.length,
  });

  return (
    <Scrollbar
      ref={messagesEndRef}
      sx={{
        ...(!messages?.length
          ? {
              display: "flex",
              flexDirection: "column",
              flex: "1 1 auto",
              height: 1,
              "& .simplebar-content": {
                display: "flex",
                flexDirection: "column",
                height: 1,
              },
            }
          : {
              height: 1,
              mb: { xs: 2, sm: 0 },
            }),
        position: "relative",
      }}
    >
      <Stack
        sx={{
          height: 1,
          p: 2,
        }}
      >
        <Stack
          alignItems="center"
          sx={{
            mb: 4,
          }}
        >
          <Avatar
            alt={name}
            src={(assistantAvatar as any)?.preview || DEFAULT_LOGO}
            sx={{ width: 80, height: 80 }}
          />
          <Typography
            variant="subtitle1"
            textAlign="center"
            sx={{
              mt: 1,
            }}
          >
            {assistant?.name || assistant?.openai?.name || name}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            textAlign="center"
            sx={{
              mt: 0.5,
            }}
          >
            {assistant?.description ||
              assistant?.openai?.description ||
              description}
          </Typography>
        </Stack>
        {(messages?.length === 0 ||
          messages[messages.length - 1]?.content === mockResponse) && (
          <Stack
            sx={{
              height: 1,
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns:
                  filteredconversationStarters?.length === 1
                    ? "0.5fr"
                    : { xs: "1fr", sm: "1fr 1fr" },
                pb: {
                  xs: 2,
                  md: filteredconversationStarters?.length > 1 ? 2 : 0,
                },
                alignItems: "stretch",
                width: 1,
                gap: 1,
                justifyContent:
                  filteredconversationStarters?.length === 1
                    ? "center"
                    : "initial",
                flexWrap: "wrap",
              }}
            >
              {filteredconversationStarters?.map((starter, index) => (
                <ChatConversationStarter starter={starter} key={index} />
              ))}
            </Box>
          </Stack>
        )}
        {!isCreatingChat.value &&
          messages.map((message, index) => {
            const isLastAssistantMessage =
              message.role === "assistant" && streamingMessageId === message.id;
            return (
              <ChatMessageItem
                key={message.id}
                message={message}
                isFunctionCalling={isFunctionCalling}
                isLastMessage={isLastAssistantMessage}
                externalContent={
                  index === messages?.length - 1 &&
                  message.role === "assistant" &&
                  noToolCalls &&
                  !message?.content?.length &&
                  status === "typing" ? (
                    <Box>
                      <TypingIndicator />
                    </Box>
                  ) : null
                }
              />
            );
          })}

        {status === "typing" &&
          messages[messages?.length - 1]?.role === "user" && (
            <ChatMessageItem
              loadingMessage
              message={{
                role: "assistant",
                content: "Typing...",
                createdAt: Timestamp.now(),
              }}
            />
          )}
        {status === "error" &&
        messages?.length &&
        messages[messages?.length - 1]?.role != "assistant" ? (
          <ChatMessageItem
            error
            message={{
              role: "assistant",
              content: eventErrorMessage || errorMessage,
              createdAt: Timestamp.now(),
            }}
          />
        ) : null}
      </Stack>
      <ScrollToButton
        isVisible={showScrollToBottomIndicator && !!messages?.length}
        color="primary"
        handleScrollBottom={scrollToBottom}
      />
    </Scrollbar>
  );
}
