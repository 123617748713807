import chroma from "chroma-js";
import COLORS from "./colors.json";
import { createPaletteChannel } from "./styles";
import {
  adjustColorForBackground,
  determineMode,
  findBestTextColor,
  isColorsTooSimilar,
  isColorTooBright,
} from "./utils";
import { alpha } from "@mui/material";

// ----------------------------------------------------------------------

export type ColorSchema =
  | "primary"
  | "secondary"
  | "info"
  | "success"
  | "warning"
  | "error";

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}

// SETUP COLORS

export const grey = createPaletteChannel(COLORS.grey);

// Primary
export const primary = createPaletteChannel(COLORS.primary);

// Secondary
export const secondary = createPaletteChannel(COLORS.secondary);

// Info
export const info = createPaletteChannel(COLORS.info);

// Success
export const success = createPaletteChannel(COLORS.success);

// Warning
export const warning = createPaletteChannel(COLORS.warning);

// Error
export const error = createPaletteChannel(COLORS.error);

// Common
export const common = createPaletteChannel(COLORS.common);

export const text = {
  light: createPaletteChannel({
    primary: grey[800],
    secondary: grey[600],
    disabled: grey[500],
  }),
  dark: createPaletteChannel({
    primary: "#FFFFFF",
    secondary: grey[500],
    disabled: grey[600],
  }),
};

// Background
export const background = {
  light: {
    paper: "#FFFFFF",
    default: "#F9F9F9",
    neutral: grey[200],
  },
  dark: {
    paper: grey[800],
    default: grey[900],
    neutral: alpha(grey[500], 0.12),
  },
};

// Action
export const baseAction = {
  hover: alpha(grey[500], 0.08),
  selected: alpha(grey[500], 0.16),
  focus: alpha(grey[500], 0.24),
  disabled: alpha(grey[500], 0.8),
  disabledBackground: alpha(grey[500], 0.24),
  active: grey[500],
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
};

export const action = {
  light: { ...baseAction, active: grey[600] },
  dark: { ...baseAction, active: grey[500] },
};

export const basePalette = {
  primary,
  secondary,
  info,
  success,
  warning,
  error,
  grey,
  common,
  divider: alpha(grey[500], 0.2),
  action: baseAction,
};

export const lightPalette = {
  ...basePalette,
  mode: "light",
  text: text.light,
  background: background.light,
  action: action.light,
};

export const darkPalette = {
  ...basePalette,
  mode: "dark",
  text: text.dark,
  background: background.dark,
  action: action.dark,
};

const actionForBrightColors: typeof baseAction & { active: string } = {
  hover: alpha(grey[400], 0.08),
  selected: alpha(grey[400], 0.16),
  disabled: alpha(grey[400], 0.8),
  disabledBackground: alpha(grey[400], 0.24),
  focus: alpha(grey[400], 0.24),
  active: grey[400],
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
};

type ColorAdjustments = typeof basePalette;

type PaletteMode = "light" | "dark" | string;

function getBaseColors(
  mode: PaletteMode,
  isPrimaryMode: boolean
): ColorAdjustments {
  if (isPrimaryMode) return basePalette;

  const needsAdjustment =
    isColorsTooSimilar(mode, chroma(alpha(grey[400], 0.16)).hex()) ||
    isColorTooBright(mode);

  if (!needsAdjustment)
    return {
      ...basePalette,
      action: {
        ...basePalette.action,
      },
    };

  const bestTextColor = findBestTextColor(mode, grey[500], grey);

  return {
    ...basePalette,
    divider: alpha(bestTextColor, 0.2),
    action: {
      ...actionForBrightColors,
      active: bestTextColor,
    },
  };
}

function getLightTheme(
  mode: PaletteMode,
  isPrimaryMode: boolean,
  finalBase: ColorAdjustments
) {
  const getTextColor = (defaultColor: string, alternateColor: string) =>
    isPrimaryMode
      ? defaultColor
      : adjustColorForBackground(
          findBestTextColor(mode, alternateColor, grey),
          mode
        );

  return {
    ...finalBase,
    mode: "light",
    text: {
      primary: getTextColor(grey[800], grey[800]),
      secondary: getTextColor(grey[600], grey[600]),
      disabled: getTextColor(grey[500], grey[500]),
    },
    background: {
      default: isPrimaryMode ? "#F9F9F9" : chroma(mode).darken(0.8).hex(),
      paper: isPrimaryMode ? "#FFFFFF" : mode,
      neutral: isPrimaryMode ? grey[200] : chroma(mode).darken(0.8).hex(),
    },
    divider: alpha(getTextColor(grey[500], grey[500]), 0.2),
    action: {
      ...finalBase.action,
      active: getTextColor(grey[600], grey[600]),
    },
  };
}

function getDarkTheme(
  mode: PaletteMode,
  isPrimaryMode: boolean,
  finalBase: ColorAdjustments
) {
  const getTextColor = (defaultColor: string, alternateColor: string) =>
    isPrimaryMode
      ? defaultColor
      : adjustColorForBackground(
          findBestTextColor(mode, alternateColor, grey),
          mode
        );

  return {
    ...finalBase,
    mode: "dark",
    text: {
      primary: "#FFFFFF",
      secondary: getTextColor(grey[500], grey[300]),
      disabled: getTextColor(grey[600], grey[400]),
    },
    background: {
      paper: isPrimaryMode ? grey[800] : mode,
      default: isPrimaryMode
        ? grey[900]
        : chroma(mode).luminance() <= 0.01
        ? chroma(mode).brighten(0.5).hex()
        : chroma(mode).darken(0.75).hex(),
      neutral: isPrimaryMode
        ? alpha(grey[500], 0.12)
        : chroma(mode).luminance() <= 0.1
        ? chroma(mode).brighten().hex()
        : chroma(mode).darken(0.8).hex(),
    },
    divider: alpha(getTextColor(grey[400], grey[400]), 0.2),
    action: {
      ...finalBase.action,
      active: getTextColor(grey[300], grey[200]),
    },
  };
}

export function palette(mode: PaletteMode) {
  const isPrimaryMode = mode === "light" || mode === "dark";
  const finalMode = determineMode(mode);
  const finalBase = getBaseColors(mode, isPrimaryMode);

  return finalMode === "light"
    ? getLightTheme(mode, isPrimaryMode, finalBase)
    : getDarkTheme(mode, isPrimaryMode, finalBase);
}
