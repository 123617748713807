type ColorValue = string | RGB | HSL | HSV;

interface RGB {
  r: number;
  g: number;
  b: number;
  a?: number;
}
interface HSL {
  h: number;
  s: number;
  l: number;
  a?: number;
}
interface HSV {
  h: number;
  s: number;
  v: number;
  a?: number;
}

/**
 * Converts any supported color format to hex
 * @param color - Color in hex, hex8, rgb, hsl, hsv format or as string
 * @returns Hex color string (#RRGGBB or #RRGGBBAA)
 */
export function toHex(color: ColorValue): string {
  // Handle string inputs
  if (typeof color === "string") {
    return parseColorString(color);
  }

  // Handle object inputs
  if (isRGB(color)) {
    return rgbToHex(color);
  }
  if (isHSL(color)) {
    return hslToHex(color);
  }
  if (isHSV(color)) {
    return hsvToHex(color);
  }

  throw new Error("Invalid color format");
}

// Type guards
function isRGB(color: any): color is RGB {
  return "r" in color && "g" in color && "b" in color;
}

function isHSL(color: any): color is HSL {
  return "h" in color && "s" in color && "l" in color;
}

function isHSV(color: any): color is HSV {
  return "h" in color && "s" in color && "v" in color;
}

function parseColorString(color: string): string {
  color = color.toLowerCase().trim();

  // Hex patterns
  const hex8Pattern = /^#?([0-9a-f]{8})$/i;
  const hex6Pattern = /^#?([0-9a-f]{6})$/i;
  const hex3Pattern = /^#?([0-9a-f]{3})$/i;

  // RGB patterns
  const rgbPattern = /^rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i;
  const rgbaPattern =
    /^rgba\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*([\d.]+)\s*\)$/i;

  // HSL patterns
  const hslPattern = /^hsl\(\s*(\d+)\s*,\s*(\d+)%?\s*,\s*(\d+)%?\s*\)$/i;
  const hslaPattern =
    /^hsla\(\s*(\d+)\s*,\s*(\d+)%?\s*,\s*(\d+)%?\s*,\s*([\d.]+)\s*\)$/i;

  // HSV patterns
  const hsvPattern = /^hsv\(\s*(\d+)\s*,\s*(\d+)%?\s*,\s*(\d+)%?\s*\)$/i;

  let match;

  // Check hex8
  if ((match = color.match(hex8Pattern))) {
    return `#${match[1].toLowerCase()}`;
  }

  // Check hex6
  if ((match = color.match(hex6Pattern))) {
    return `#${match[1].toLowerCase()}`;
  }

  // Check hex3
  if ((match = color.match(hex3Pattern))) {
    const hex = match[1];
    return `#${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  // Check rgba
  if ((match = color.match(rgbaPattern))) {
    return rgbToHex({
      r: parseInt(match[1]),
      g: parseInt(match[2]),
      b: parseInt(match[3]),
      a: parseFloat(match[4]),
    });
  }

  // Check rgb
  if ((match = color.match(rgbPattern))) {
    return rgbToHex({
      r: parseInt(match[1]),
      g: parseInt(match[2]),
      b: parseInt(match[3]),
    });
  }

  // Check hsla
  if ((match = color.match(hslaPattern))) {
    return hslToHex({
      h: parseInt(match[1]),
      s: parseInt(match[2]) / 100,
      l: parseInt(match[3]) / 100,
      a: parseFloat(match[4]),
    });
  }

  // Check hsl
  if ((match = color.match(hslPattern))) {
    return hslToHex({
      h: parseInt(match[1]),
      s: parseInt(match[2]) / 100,
      l: parseInt(match[3]) / 100,
    });
  }

  // Check hsv
  if ((match = color.match(hsvPattern))) {
    return hsvToHex({
      h: parseInt(match[1]),
      s: parseInt(match[2]) / 100,
      v: parseInt(match[3]) / 100,
    });
  }

  throw new Error("Invalid color string format");
}

function rgbToHex({ r, g, b, a }: RGB): string {
  if (!isValidRGB(r) || !isValidRGB(g) || !isValidRGB(b)) {
    throw new Error("RGB values must be between 0 and 255");
  }

  const hex = `#${[r, g, b]
    .map((x) => Math.round(x).toString(16).padStart(2, "0"))
    .join("")}`;

  if (a !== undefined) {
    if (a < 0 || a > 1) {
      throw new Error("Alpha value must be between 0 and 1");
    }
    const alpha = Math.round(a * 255)
      .toString(16)
      .padStart(2, "0");
    return `${hex}${alpha}`;
  }

  return hex;
}

// ... [Previous hslToHex and hsvToHex functions remain the same] ...

function isValidRGB(value: number): boolean {
  return Number.isInteger(value) && value >= 0 && value <= 255;
}

function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}

function hslToHex({
  h,
  s,
  l,
  a,
}: {
  h: number;
  s: number;
  l: number;
  a?: number;
}): string {
  // Normalize values
  h = ((h % 360) + 360) % 360;
  s = clamp(s, 0, 1);
  l = clamp(l, 0, 1);

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;

  let [r, g, b] = [0, 0, 0];

  if (h >= 0 && h < 60) [r, g, b] = [c, x, 0];
  else if (h >= 60 && h < 120) [r, g, b] = [x, c, 0];
  else if (h >= 120 && h < 180) [r, g, b] = [0, c, x];
  else if (h >= 180 && h < 240) [r, g, b] = [0, x, c];
  else if (h >= 240 && h < 300) [r, g, b] = [x, 0, c];
  else if (h >= 300 && h < 360) [r, g, b] = [c, 0, x];

  return rgbToHex({
    r: Math.round((r + m) * 255),
    g: Math.round((g + m) * 255),
    b: Math.round((b + m) * 255),
    a,
  });
}

function hsvToHex({
  h,
  s,
  v,
  a,
}: {
  h: number;
  s: number;
  v: number;
  a?: number;
}): string {
  // Normalize values
  h = ((h % 360) + 360) % 360;
  s = clamp(s, 0, 1);
  v = clamp(v, 0, 1);

  const c = v * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = v - c;

  let [r, g, b] = [0, 0, 0];

  if (h >= 0 && h < 60) [r, g, b] = [c, x, 0];
  else if (h >= 60 && h < 120) [r, g, b] = [x, c, 0];
  else if (h >= 120 && h < 180) [r, g, b] = [0, c, x];
  else if (h >= 180 && h < 240) [r, g, b] = [0, x, c];
  else if (h >= 240 && h < 300) [r, g, b] = [x, 0, c];
  else if (h >= 300 && h < 360) [r, g, b] = [c, 0, x];

  return rgbToHex({
    r: Math.round((r + m) * 255),
    g: Math.round((g + m) * 255),
    b: Math.round((b + m) * 255),
    a,
  });
}
