"use client";
import React from "react";
import { ComponentTag } from "../markdown";
import { useCopyToClipboard } from "../../../hooks/use-copy-clipboard";
import { IconButton, Stack, Typography } from "@mui/material";
import Iconify from "../../iconify";

const extractText = (child: any): string => {
  if (typeof child === "string") {
    return child;
  }
  if (Array.isArray(child)) {
    return child.map(extractText).join("");
  }
  if (child?.props?.children) {
    return extractText(child.props.children);
  }
  return "";
};

const LanguageCodeblock = ({ children, language }: ComponentTag) => {
  const { copy, isCopied } = useCopyToClipboard();

  const codeText = Array.isArray(children)
    ? children.map(extractText).join("")
    : extractText(children);

  return (
    <>
      <Typography
        variant="caption"
        sx={{
          color: (theme) => theme.palette.grey[400],
          position: "absolute",
          px: 2,
          pt: 1.5,
          top: 0,
          left: 0,
        }}
      >
        {language}
      </Typography>

      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{
          px: 1,
          pt: 1,
          pb: 1.5,
          width: 1,
          position: "sticky",
          top: 3,
          zIndex: 1,
          // backgroundColor: (theme) =>
          //   theme.palette.grey[theme.palette.mode === "light" ? 800 : 700],
        }}
      >
        <IconButton
          onClick={() => {
            copy(codeText);
          }}
          size="small"
          sx={{
            color: (theme) => theme.palette.common.white,
            backgroundColor: (theme) =>
              theme.palette.grey[theme.palette.mode === "light" ? 800 : 900],
          }}
          disabled={isCopied}
        >
          <Iconify
            sx={{
              width: 18,
              height: 18,
            }}
            icon={isCopied ? "eva:checkmark-fill" : "fluent:copy-16-filled"}
          />
        </IconButton>
      </Stack>
    </>
  );
};

export default LanguageCodeblock;
