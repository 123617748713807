"use client";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { BotStatus, ChatDoc, Message } from "./types";
import { useAuth } from "reactfire";
import { useWidgetSettingsContext } from "./settings";
import { createChatDoc, getMessage } from "./lib/firestore";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  getSessionStorage,
  setSessionStorage,
} from "./hooks/use-session-storage";
import { useBoolean } from "./hooks/use-boolean";
import { ReturnType } from "./hooks/use-boolean";
import { useSnackbar } from "notistack";
import { getIdToken, User } from "firebase/auth";
import { constructUrl } from "./utils/constructUrl";
import { parseSSEData } from "./utils/parseSSEData";
import { FunctionCall } from "./lib/types";
import { appCheckInit, widgetAuth } from "./lib/firebase";
import useAuthState from "./hooks/use-auth-state";
import { getToken } from "firebase/app-check";
import useChatMessages from "./hooks/use-widget-chat";

interface NewMessageEvent {
  event: "new_message";
  data: string;
}

interface LastMessageContentEvent {
  event: "last_message.content";
  data: string;
}

interface LastMessageFunctionEvent {
  event: "last_message.function";
  data: FunctionCall;
}

interface LastMessageContentDeltaEvent {
  event: "last_message.content.delta";
  data: string;
}

interface EndEvent {
  event: "end";
  data: {};
}

interface ErrorEvent {
  event: "error_message";
  data: string;
}

type Event =
  | NewMessageEvent
  | LastMessageContentEvent
  | LastMessageFunctionEvent
  | LastMessageContentDeltaEvent
  | EndEvent
  | ErrorEvent;

interface ChatContextType {
  messages: Message[];
  errorMessage: string;
  eventErrorMessage: string;
  sendMessage: (userMessage: string) => void;
  clearConversation: ({ persistMode }: { persistMode?: boolean }) => void;
  status: BotStatus;
  setStatus: React.Dispatch<React.SetStateAction<BotStatus>>;
  chatOpen: boolean;
  setChatOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editMode?: boolean;
  isHome?: boolean;
  chatId?: string | null | undefined;
  docMessages: Message[];
  isCreatingChat: ReturnType;
  userLoading: boolean;
  user: User | null | undefined;
  isFunctionCalling: string[];
  streamingMessageId: string | null;
}

export const ChatContext = React.createContext<ChatContextType>({
  clearConversation: () => {},
  messages: [],
  sendMessage: () => {},
  status: "online",
  setStatus: () => {},
  chatOpen: false,
  setChatOpen: () => {},
  errorMessage: "",
  eventErrorMessage: "",
  chatId: undefined,
  docMessages: [],
  isCreatingChat: {
    onFalse: () => {},
    onTrue: () => {},
    value: false,
    onToggle: () => {},
    setValue: () => {},
  },
  userLoading: false,
  user: null,
  isFunctionCalling: [],
  streamingMessageId: null,
});

interface ChatContextProviderProps {
  children: React.ReactNode;
  editMode?: boolean;
  widgetId?: string;
  defaultOpen?: boolean;
  isHome?: boolean;
  additionalInstructions?: string;
}

let eventSource: EventSource | null = null;

// ----------------------------------------------------------------------

export const ChatContextProvider: React.FC<ChatContextProviderProps> = ({
  children,
  editMode,
  widgetId,
  defaultOpen,
  isHome,
  additionalInstructions,
}) => {
  const [messages, setMessages] = useState<ChatDoc["messages"]>([]);
  const [status, setStatus] = useState<BotStatus>("online");
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [chatOpen, setChatOpen] = useState<boolean>(
    (!!editMode || !!defaultOpen) && isSmUp
  );
  const auth = useAuth();
  const [user, userLoading, error] = useAuthState(auth, {
    enableAnonymousAuth: !editMode,
  });

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [eventErrorMessage, setEventErrorMessage] = useState<string>("");
  const [skipFetch, setSkipFetch] = useState<boolean>(false);
  const [isFunctionCalling, setIsFunctionCalling] = useState<string[]>([]);
  const [streamingMessageId, setSteamingMessage] = useState<string | null>(
    null
  );

  const hasCreatedConversationRef = useRef(false);
  const currentChatIdRef = useRef<string | null>(null);
  const isCreatingChat = useBoolean(false);

  const widgetSettings = useWidgetSettingsContext();

  const assistantId =
    widgetSettings?.assistant?.assistantId || widgetSettings?.assistant;
  const conversationStarters = widgetSettings.conversationStarters;

  const shouldPersist = widgetSettings.chatPersistence === "tabClosed";

  const [chatId, setChatId] = useState<string | undefined | null>(
    getSessionStorage(`user-widget-id-${widgetId}`)
  );

  const { enqueueSnackbar } = useSnackbar();

  const { messagesData, status: messStatus } = useChatMessages({
    widgetId,
    selectedChatId: chatId,
    skip: skipFetch,
  });

  useEffect(() => {
    if (editMode && !assistantId) {
      return;
    }

    if (messagesData && messStatus === "success") {
      setMessages(
        [...messagesData].sort(
          (mes1, mes2) => mes1.createdAt.toDate() - mes2.createdAt.toDate()
        ) as any
      );
    }
  }, [messagesData]);

  const isTryingSend = useBoolean();

  const cleanupEventSource = useCallback(() => {
    if (eventSource) {
      eventSource.close();
      eventSource = null;
    }
  }, [eventSource]);

  const handleSSEMessage = useCallback(
    async (chatId: string) => {
      const userIdToken = await getIdToken(widgetAuth.currentUser!);

      const appCheckTokenResponse = await getToken(appCheckInit!);

      currentChatIdRef.current = chatId;

      const url = constructUrl({
        chatId,
        widgetId: widgetId,
        assistantId: assistantId,
        userToken: userIdToken,
        appCheckToken: appCheckTokenResponse.token,
        additionalInstructions,
      });

      eventSource = new EventSource(url);

      eventSource.addEventListener("new_message", (event) => {
        const newMessage = event.data;
        handleFirestoreSSE(
          {
            event: "new_message",
            data: newMessage,
          },
          chatId
        );
      });

      eventSource.addEventListener("last_message.function", (event) => {
        const data = event.data;

        handleFirestoreSSE(
          {
            event: "last_message.function",
            data: data,
          },
          chatId
        );
      });

      eventSource.addEventListener("last_message.content", (event) => {
        const data = event.data as string;

        handleFirestoreSSE(
          {
            event: "last_message.content",
            data: data,
          },
          chatId
        );
      });

      eventSource.addEventListener("last_message.content.delta", (event) => {
        const data = event.data as string;

        handleFirestoreSSE(
          {
            event: "last_message.content.delta",
            data: data,
          },
          chatId
        );
      });

      eventSource.addEventListener("error_message", (event) => {
        const data = (event as MessageEvent).data as unknown as string;
        handleFirestoreSSE(
          {
            event: "error_message",
            data: data,
          },
          chatId
        );
      });

      eventSource.addEventListener("end", (event) => {
        cleanupEventSource();
        setStatus("online");
        setIsFunctionCalling([]);
        setSteamingMessage(null);
        setTimeout(() => {
          setSkipFetch(false);
        }, 500);
      });

      eventSource.addEventListener("abort", (event) => {
        console.log("abort");
      });

      eventSource.onerror = (error) => {
        console.error("EventSource error:", error);
        cleanupEventSource();
        setErrorMessage("Something went wrong.");
        setStatus("error");
        setIsFunctionCalling([]);
        setSteamingMessage(null);
        setSkipFetch(false);
      };

      return () => {
        cleanupEventSource();
      };
    },
    [widgetSettings.widgetId, assistantId, additionalInstructions]
  );

  const sendMessage = useCallback(
    async (userMessage: string) => {
      isTryingSend.onTrue();
      cleanupEventSource();
      setSkipFetch(false);
      try {
        setErrorMessage("");
        setStatus("online");

        if (editMode && !widgetSettings.assistant) {
          enqueueSnackbar({
            message: "An agent wasn`t configured for this widget.",
            variant: "error",
          });
          return;
        } else {
          const currentChatId = getSessionStorage(`user-widget-id-${widgetId}`);
          if (
            !currentChatId &&
            widgetSettings.widgetId &&
            widgetSettings.ownerUid
          ) {
            const newChatId = await createChatDoc(
              widgetSettings.widgetId,
              user?.uid || widgetSettings.ownerUid
            );
            if (newChatId) {
              setSessionStorage(`user-widget-id-${widgetId}`, newChatId);
              setChatId(newChatId);
              await getMessage(
                widgetSettings.widgetId!,
                newChatId,
                userMessage
              ).then(() => setSkipFetch(true));
              setStatus("typing");
              await handleSSEMessage(newChatId);
            }
          } else {
            if (chatId && widgetSettings.widgetId) {
              setStatus("typing");
              await getMessage(
                widgetSettings.widgetId!,
                chatId,
                userMessage
              ).then(() => setSkipFetch(true));
              await handleSSEMessage(chatId);
            } else {
              const newChatId = await createChatDoc(
                widgetSettings.widgetId!,
                user?.uid || widgetSettings.ownerUid!
              );
              setChatId(newChatId);
              setSessionStorage(`user-widget-id-${widgetId}`, newChatId);
              setStatus("typing");
              await getMessage(
                widgetSettings.widgetId!,
                newChatId,
                userMessage
              ).then(() => setSkipFetch(true));

              await handleSSEMessage(newChatId);
            }
          }
        }
      } catch (error: any) {
        setErrorMessage(error?.message);
        setStatus("error");
        console.error("Error sending message:", error);
      } finally {
        isTryingSend.onFalse();
      }
    },
    [
      messagesData,
      status,
      conversationStarters,
      user?.uid,
      chatId,
      handleSSEMessage,
    ]
  );

  const clearConversation = useCallback(
    async ({ persistMode }: { persistMode?: boolean }) => {
      if (persistMode && messagesData && !messagesData?.length) return;
      cleanupEventSource();
      setMessages([]);
      const id = widgetId || widgetSettings.widgetId;
      const newChatId = await createChatDoc(
        id!,
        user?.uid || widgetSettings.ownerUid!
      );
      setChatId(newChatId);
      setSessionStorage(`user-widget-id-${widgetId}`, newChatId);
      currentChatIdRef.current = newChatId;
      setStatus("online");
      setTimeout(() => {
        setSkipFetch(false);
      }, 500);
    },
    [widgetSettings, widgetId, messagesData]
  );

  const handleFirestoreSSE = useCallback(
    async (event: Event, chatId: string) => {
      if (currentChatIdRef.current !== chatId) {
        console.log(
          `Ignoring event for chat ${chatId}, current chat is ${currentChatIdRef.current}`
        );
        return;
      }
      switch (event.event) {
        case "new_message":
          const newMessage = parseSSEData(event.data);
          setSteamingMessage(newMessage.id as string);
          setMessages((prevMessages) =>
            prevMessages ? [...prevMessages, newMessage] : prevMessages
          );
          break;
        case "last_message.content":
          const lastMessageContent = event.data as string;

          setMessages((prevMessages) => {
            if (!prevMessages || prevMessages.length === 0) return prevMessages;
            const lastIndex = prevMessages.length - 1;
            return [
              ...prevMessages.slice(0, lastIndex),
              { ...prevMessages[lastIndex], content: lastMessageContent },
            ];
          });
          break;
        case "last_message.content.delta":
          const contentDelta = event.data as string;
          setMessages((prevMessages) => {
            return prevMessages.map((msg, idx) =>
              idx === prevMessages.length - 1
                ? { ...msg, content: msg.content + contentDelta }
                : msg
            );
          });
          break;
        case "last_message.function":
          const lastMessageFunction = event.data as FunctionCall;

          setMessages((prevMessages) => {
            if (!prevMessages || prevMessages.length === 0) return prevMessages;

            const updatedMessages = [...prevMessages];
            const lastMessage = updatedMessages[updatedMessages.length - 1];
            const parsedlastMessageFunction = JSON.parse(
              lastMessageFunction as unknown as string
            );

            const updatedLastMessage = {
              ...lastMessage,
              functions: {
                ...(lastMessage.functions || {}),
                [parsedlastMessageFunction.id]: parsedlastMessageFunction,
              },
            };
            if (parsedlastMessageFunction.id)
              setIsFunctionCalling((prev) =>
                Array.from(new Set([...prev, parsedlastMessageFunction.id]))
              );
            updatedMessages[updatedMessages.length - 1] = updatedLastMessage;
            return updatedMessages;
          });
          break;

        case "error_message":
          const errorMessage = event.data as string;
          setEventErrorMessage(errorMessage || "Something went wrong.");
          setStatus("error");
          setSteamingMessage(null);
          break;

        default:
          console.warn(`Unhandled event type: ${event}`);
          break;
      }
    },
    []
  );

  const isConversationWithMessages =
    messStatus === "success" && messagesData?.length > 0;

  useEffect(() => {
    if (messStatus === "success" && !hasCreatedConversationRef.current) {
      if (shouldPersist && isConversationWithMessages && !isTryingSend.value) {
        isCreatingChat.onTrue();
        clearConversation({ persistMode: true }).then(() => {
          setTimeout(() => {
            isCreatingChat.onFalse();
          }, 200);
        });
      }
      hasCreatedConversationRef.current = true;
    }
  }, [shouldPersist, messagesData, messStatus, isTryingSend.value]);

  const docMessagesData = isCreatingChat.value ? [] : messagesData || [];

  const context = useMemo(
    () => ({
      errorMessage,
      clearConversation,
      messages,
      sendMessage,
      status,
      setStatus,
      chatOpen,
      setChatOpen,
      editMode,
      isHome,
      chatId,
      docMessages: docMessagesData,
      isCreatingChat,
      userLoading,
      user,
      isFunctionCalling,
      streamingMessageId,
      eventErrorMessage,
    }),
    [
      chatOpen,
      clearConversation,
      messages,
      sendMessage,
      status,
      editMode,
      isHome,
      errorMessage,
      chatId,
      messagesData,
      isCreatingChat,
      userLoading,
      user,
      isFunctionCalling,
      streamingMessageId,
      eventErrorMessage,
    ]
  );

  return (
    <ChatContext.Provider value={context}>{children}</ChatContext.Provider>
  );
};
