import React, { useCallback, useEffect } from "react";

import { useContext, useState } from "react";
// @mui
import {
  InputBase,
  IconButton,
  Stack,
  CircularProgress,
  useTheme,
} from "@mui/material";
// components
import { ChatContext } from "../context";
import { useWidgetSettingsContext } from "../settings";
import Iconify from "../components/iconify";

import { alpha } from "@mui/material";
import { uploadVoiceInput } from "../lib/firestore";
import { generateTranscription } from "../lib/firebase";
import { useDebounceCallback } from "../hooks/use-debounce-callback";
import { VoiceVisualizer, useVoiceVisualizer } from "react-voice-visualizer";

// ----------------------------------------------------------------------

export default function ChatMessageInput() {
  const [message, setMessage] = useState("");
  const { status, sendMessage } = useContext(ChatContext);
  const { inputPlaceholder, widgetId, voiceInput } = useWidgetSettingsContext();
  const theme = useTheme();

  const handleSend = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      event.stopPropagation();

      if (event.key !== "Enter" || status === "typing") return;

      const trimmedMessage = message.trim();
      if (trimmedMessage) {
        sendMessage(trimmedMessage);
        setMessage("");
      }
    },
    [message, status, sendMessage]
  );

  const handleClickSend = useCallback(() => {
    const trimmedMessage = message.trim();
    if (trimmedMessage) {
      sendMessage(message);
    }
    setMessage("");
  }, [message]);

  const [isTranscribing, setIsTranscribing] = useState(false);
  const recorderControls = useVoiceVisualizer();
  const [recorderedBlob, setRecorderedBlob] = useState<Blob | undefined | null>(
    recorderControls.recordedBlob
  );
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const inputRef = React.useRef<HTMLTextAreaElement | null>(null);

  const getTranscriptions = useCallback(
    async (blob: Blob) => {
      if (!voiceInput) return;
      try {
        const filePath = await uploadVoiceInput({
          file: blob,
          widgetId: widgetId || "1",
          chatId: "test-widget",
        });
        await generateTranscription({
          filePath,
          widgetId: widgetId,
        }).then((res) => {
          const text = res.data.text;
          if (text) {
            const messageToSend = message?.trim()?.length
              ? `${message} ${text}`
              : text;
            setMessage(messageToSend);

            setRecorderedBlob(undefined);
          }
        });
      } catch (error) {
        setRecorderedBlob(undefined);
      } finally {
        setIsTranscribing(false);
      }
    },
    [message, voiceInput]
  );

  useEffect(() => {
    if (!recorderControls.recordedBlob || !voiceInput) return;
    setRecorderedBlob(recorderControls.recordedBlob);
    recorderControls.recordedBlob = null;
  }, [recorderControls.recordedBlob, voiceInput]);

  useEffect(() => {
    if (!recorderedBlob) return;

    setIsTranscribing(true);
    getTranscriptions(recorderedBlob);
  }, [recorderedBlob]);

  const checkMultiline = useDebounceCallback(() => {
    const textareaEl = inputRef.current;
    if (!textareaEl) {
      setHasScrollbar(false);
      return;
    }

    const computedStyle = window.getComputedStyle(textareaEl);
    const lineHeight = parseInt(computedStyle.lineHeight) || 20;

    const containerWidth = textareaEl.parentElement?.clientWidth ?? 0;
    const availableWidth = containerWidth - 70;

    const measurer = document.createElement("div");
    measurer.style.cssText = `
        position: absolute;
        visibility: hidden;
        width: ${availableWidth}px;
        font-family: ${computedStyle.fontFamily};
        font-size: ${computedStyle.fontSize};
        letter-spacing: ${computedStyle.letterSpacing};
        white-space: pre-wrap;
        word-wrap: break-word;
      `;
    measurer.textContent = textareaEl.value;
    document.body.appendChild(measurer);

    const totalHeight = measurer.offsetHeight;
    const lines = Math.ceil(totalHeight / lineHeight);

    document.body.removeChild(measurer);

    const shouldShowScrollbar = lines > 3.5;

    if (hasScrollbar !== shouldShowScrollbar) {
      setHasScrollbar(shouldShowScrollbar);
    }
  }, 50);

  useEffect(() => {
    if (!voiceInput) return setHasScrollbar(false);
    checkMultiline();

    window.addEventListener("resize", checkMultiline);

    return () => {
      window.removeEventListener("resize", checkMultiline);
    };
  }, [message, voiceInput]);

  return recorderControls.isRecordingInProgress ? (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
        "& .voice-visualizer": {
          width: 1,
        },
      }}
    >
      <VoiceVisualizer
        controls={recorderControls}
        isControlPanelShown={false}
        width={"95%"}
        height={56}
        speed={1}
        mainBarColor={theme.palette.text.primary}
        secondaryBarColor={theme.palette.text.secondary}
      />
      <IconButton
        size="small"
        onClick={recorderControls.stopRecording}
        sx={{ mr: 1 }}
        color="error"
      >
        <Iconify icon="icon-park-outline:voice-off" />
      </IconButton>
    </Stack>
  ) : (
    <InputBase
      value={message}
      multiline
      maxRows={4}
      onKeyUp={(e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();
          handleSend(e);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();
        }
      }}
      onChange={(event) => setMessage(event.target.value)}
      placeholder={inputPlaceholder}
      disabled={isTranscribing}
      inputRef={inputRef}
      endAdornment={
        <Stack height={1} marginLeft={1} justifyContent="flex-end">
          <Stack
            direction={hasScrollbar ? "column" : "row"}
            gap={1}
            justifyContent={hasScrollbar ? "space-evenly" : "center"}
            alignItems="center"
            mb={hasScrollbar ? 0 : 1}
            height={hasScrollbar ? 1 : "auto"}
          >
            {voiceInput && (
              <IconButton
                disabled={isTranscribing || status === "typing"}
                size="small"
                onClick={recorderControls.startRecording}
              >
                {isTranscribing ? (
                  <CircularProgress disableShrink size={18} />
                ) : (
                  <Iconify icon="icon-park-solid:voice" />
                )}
              </IconButton>
            )}
            <IconButton
              disabled={status === "typing" || !message.trim()?.length}
              size="small"
              onClick={handleClickSend}
            >
              <Iconify icon="ic:baseline-send" />
            </IconButton>
          </Stack>
        </Stack>
      }
      sx={{
        px: 2,
        minHeight: 56,
        flexShrink: 0,
        borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
        "& textarea": {
          scrollbarWidth: "thin",
          scrollbarColor: (theme) =>
            `${alpha(theme.palette.text.disabled, 0.4)} ${alpha(
              theme.palette.text.disabled,
              0.08
            )}`,
        },
        "& input::placeholder": {
          fontWeight: 500,
        },
        "&.MuiInputBase-root.Mui-disabled svg": {
          color: "inherit",
        },
      }}
      id="chat-widget-input-wrapper"
    />
  );
}
