import chroma from "chroma-js";
import { toHex } from "../utils/toHexColor";

const COLOR_CONSTANTS = {
  BRIGHTNESS_THRESHOLD: 0.45,
  SATURATION_THRESHOLD: 0.7,
  MINIMUM_CONTRAST: 2.5,
  MINIMUM_DELTA_E: 5.0,
  FALLBACK_CONTRAST: 2.5,
};

export function isColorTooBright(color: string) {
  const chromaColor = chroma(color);
  const saturation = chromaColor.get("hsl.s");
  const brightness = chromaColor.luminance();

  return (
    saturation > COLOR_CONSTANTS.SATURATION_THRESHOLD ||
    brightness > COLOR_CONSTANTS.BRIGHTNESS_THRESHOLD
  );
}

export function isColorsTooSimilar(color1: string, color2: string) {
  const contrastRatio = chroma.contrast(color1, color2);
  const deltaE = chroma.deltaE(color1, color2);

  return (
    contrastRatio < COLOR_CONSTANTS.FALLBACK_CONTRAST ||
    deltaE < COLOR_CONSTANTS.MINIMUM_DELTA_E
  );
}

export function findBestTextColor(
  bgColor: string,
  preferred: string,
  palette: Record<number, string>
): string {
  const initialContrast = chroma.contrast(preferred, bgColor);
  const targetContrast = COLOR_CONSTANTS.MINIMUM_CONTRAST;
  const preferredLuminance = chroma(preferred).luminance();
  const bgLuminance = chroma(bgColor).luminance();

  if (initialContrast >= targetContrast) {
    return preferred;
  }

  // Determine if we should look for a darker or lighter color
  const shouldBeDarker = preferredLuminance < bgLuminance;

  return Object.entries(palette)
    .filter(([key]) => !key.endsWith("Channel"))
    .map(([_, color]) => color)
    .filter((color) => {
      const colorLuminance = chroma(color).luminance();
      // Filter colors based on whether they should be darker or lighter
      return shouldBeDarker
        ? colorLuminance <= preferredLuminance
        : colorLuminance >= preferredLuminance;
    })
    .reduce(
      (best, color) => {
        const contrast = chroma.contrast(color, bgColor);
        if (contrast < targetContrast * 0.65) return best;

        const luminanceDiff = Math.abs(
          chroma(color).luminance() - preferredLuminance
        );
        const bestLuminanceDiff = Math.abs(
          chroma(best.color).luminance() - preferredLuminance
        );

        return luminanceDiff < bestLuminanceDiff ? { color, contrast } : best;
      },
      { color: preferred, contrast: initialContrast }
    ).color;
}

export function adjustColorForBackground(
  color: string,
  bgColor: string
): string {
  const contrast = chroma.contrast(color, bgColor);
  if (contrast >= COLOR_CONSTANTS.MINIMUM_CONTRAST) return color;

  const isDarkBg = chroma(bgColor).luminance() < 0.5;
  return isDarkBg
    ? chroma(color).brighten(1).hex()
    : chroma(color).darken(1).hex();
}

export function determineMode(
  mode: "light" | "dark" | string
): "light" | "dark" {
  if (mode === "light" || mode === "dark") return mode;
  const hexColor = toHex(mode);
  return chroma(hexColor).luminance() > COLOR_CONSTANTS.BRIGHTNESS_THRESHOLD
    ? "light"
    : "dark";
}
