import { useCallback, useMemo, useRef } from "react";
import { debounce } from "../utils/debounce";


/**
 * @name useDebounceCallback
 * @description - Hook that creates a debounced callback and returns a stable reference of it
 * @category Utilities
 *
 * @template Params The type of the params
 * @template Return The type of the return
 * @param {(...args: Params) => Return} callback The callback function
 * @param {number} delay The delay in milliseconds
 * @returns {(...args: Params) => Return} The debounced callback
 *
 * @example
 * const debounced = useDebounceCallback(() => console.log('callback'), 500);
 */
export const useDebounceCallback = <Params extends unknown[], Return>(
  callback: (...args: Params) => Return,
  delay: number
): ((...args: Params) => void) => {
  const callbackRef = useRef(callback);

  // Keep the callback reference updated
  callbackRef.current = callback;

  // Memoize the debounced function
  const debounced = useMemo(
    () =>
      debounce((...args: Params) => {
        const fn = callbackRef.current;
        return fn(...args);
      }, delay),
    [delay]
  );

  // Return a stable reference to the debounced function
  return useCallback((...args: Params) => debounced(...args), [debounced]);
};
